@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap");
@import url("../public/assets/fonts/stylesheet.css");

@layer base {
  html {
    @apply text-gray-800;
  }
  p {
    @apply text-gray-600;
  }
  h1 {
    @apply text-3xl sm:text-4xl font-medium mb-5;
  }
  h2 {
    @apply text-2xl sm:text-3xl font-medium mb-5;
  }
  h3 {
    @apply text-xl sm:text-2xl font-medium mb-5;
  }

  .dash-sidebar {
    max-width: 250px;
  }
}

.job-post-section > ul {
  list-style: disc;
  padding-left: 20px;
}

@layer components {
  .dashboard-sidenav {
    @apply font-Karla;
  }
  .dashboard-sidenav > li.active > a,
  .subnav-item.active > a {
    @apply bg-sky-500 text-white;
  }

  /* calendar start */
  .calendar {
    text-align: center;
  }

  .calendar-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }

  .calendar-header {
    /* background-color: #222; */
    background-color: #148476;
    height: 150px;
    padding: 20px;
    color: white;
  }

  .calendar-title {
    font-size: 2.5em;
  }

  .calendar-intro {
    font-size: 2.5em;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  /* calendar end   */

  .menu-items a {
    @apply text-gray-700 hover:text-blue-700;
  }

  .form-control {
    @apply block w-full py-2 p-3 bg-gray-50 border border-gray-300 rounded shadow-sm placeholder-gray-400
			focus:outline-none focus:border-blue focus:ring-1 focus:ring-blue focus:bg-white
			transition-all
			ease-in-out	
			duration-100
			text-base
			h-[55px];
  }
  .form-control:focus {
    box-shadow: 0 0 0 1px #193e8c;
  }
  .form-control-sm {
    @apply h-8 py-0 rounded;
  }

  .checkbox-form-group {
    @apply inline-flex items-center;
  }
  .checkbox-form-control {
    @apply w-4 h-4 rounded mr-2 border-gray-300 
		focus:outline-none 
		transition-all
		ease-in-out	
		duration-100
		font-Karla
		text-sm;
  }
  .radio-form-control {
    @apply w-4 h-4 rounded mr-2 border-gray-300 
		focus:outline-none 
		transition-all
		ease-in-out	
		duration-100
		font-Karla
		text-sm;
  }

  .control-label {
    /* font-weight: 400; */
    @apply text-sm
			font-Karla;
  }
  .help-block {
    @apply text-xs
			font-Karla;
  }

  .form-control-checkbox {
    @apply appearance-none 
			h-4 
			w-4 
			border border-gray-300 
			rounded-sm bg-white 
			checked:bg-blue-600 checked:border-blue-600 
			focus:outline-none transition duration-200 
			mt-1 align-top bg-no-repeat 
			bg-center bg-contain 
			float-left mr-2 
			cursor-pointer;
  }
  .form-control-radio {
    @apply appearance-none 
			rounded-full 
			h-4 w-4 
			border border-gray-300 
			bg-white 
			checked:bg-blue-600 
			checked:border-blue-600 
			focus:outline-none transition duration-200 
			mt-1 align-top bg-no-repeat 
			bg-center bg-contain 
			float-left mr-2 
			cursor-pointer;
  }
  .form-control-checkbox:hover,
  .form-control-radio:hover {
    @apply shadow-md;
  }
  .form-control-checkbox:checked {
    /* background-color:#0d6efd; */
    /* border-color:#0d6efd; */
    @apply bg-blue-400 border-blue-400;
  }
  .form-control-checkbox:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  .form-control-checkbox:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%222%22 fill=%22%23fff%22/%3E%3C/svg%3E");
  }
  .form-control-checkbox[type="checkbox"]:indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' d='M6 10h8'/%3e%3c/svg%3e");
    @apply bg-blue-400 border-blue-400;
  }
  .form-control-checkbox:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
  }

  .svg-wrap svg {
    @apply w-full h-auto;
  }

  .custom-filter {
    @apply h-10;
  }
  /* .custom-calendar-filter {
		@apply
		
	} */
  .custom-calendar-filter .react-datepicker-wrapper {
    @apply w-full;
  }
  .custom-calendar-filter .form-control {
    @apply h-10;
  }
  .custom-calendar-filter .form-control > div {
    @apply top-[10px];
  }

  /* .card-wrap{
		@apply flex-col
	} */

  .btn {
    @apply inline-flex justify-center items-center
			rounded-md bg-gray-200 shadow-sm 
			hover:bg-gray-500 hover:text-white
			transition-all
			ease-in-out	
			duration-200
			font-Karla
			font-normal
			/* text-sm */
			disabled:opacity-70 disabled:cursor-not-allowed;
  }

  .btn-white {
    @apply bg-white text-black outline-blue font-Karla border border-gray-100;
  }
  .btn-white:hover,
  .btn-white:focus {
    /* box-shadow: 0 0 0 4px #193E8C; */
    @apply bg-white text-blue-500
			shadow-lg;
  }

  .btn-tertiary {
    @apply px-10 py-3 inline-block bg-orange-300 rounded-xl text-white;
  }
  .btn-tertiary:hover,
  .btn-tertiary:focus {
    /* box-shadow: 0 0 0 4px #193E8C; */
    @apply bg-orange-800 text-white;
  }

  .btn-CTA {
    @apply px-10 py-3 inline-block bg-transparent border border-light-blue rounded-full text-white
			font-Karla;
  }
  .btn-CTA:hover,
  .btn-CTA:focus {
    /* box-shadow: 0 0 0 4px #193E8C; */
    @apply bg-light-blue-400 text-white;
  }

  .btn-Tabs {
    @apply pb-5 border-b-2 border-transparent text-grayBG-300;
  }
  .btn-Tabs:hover,
  .btn-Tabs:focus {
    /* box-shadow: 0 0 0 4px #193E8C; */
    @apply /* pb-5 
			border-b-2  */
			border-blue-300 
			text-blue-300
			bg-white;
  }

  .btn-DashTabs {
    @apply pb-5 border-b border-grayBG-200 text-grayBG-300;
  }
  .btn-DashTabs:hover,
  .btn-DashTabs:focus {
    /* box-shadow: 0 0 0 4px #193E8C; */
    @apply /* pb-5  */
			border-b-3 
			border-light-blue 
			text-black
			bg-transparent;
  }

  .btn-Radio {
    @apply rounded-full border border-grayBG-200;
  }
  .btn-Radio:hover,
  .btn-Radio:focus {
    box-shadow: 0 0 0 2px #193e8c;
    @apply rounded-full border-5 border-white bg-blue;
  }

  .btn-CheckBox {
    @apply rounded-sm border border-grayBG-200;
  }
  .btn-CheckBox:hover,
  .btn-CheckBox:focus {
    box-shadow: 0 0 0 2px #193e8c;
    @apply rounded-sm border-blue;
  }

  /* MAIN NAV */
  #main-nav a {
    /* @apply */
    /* font-Karla */
  }
  #main-nav-items .nav-item,
  #nav-right-menu .nav-item {
    @apply relative font-bold;
  }
  #main-nav-items .nav-item {
    padding: 10px 15px;
    margin: 0 5px;
  }
  #nav-right-menu .nav-item {
    padding: 10px 5px;
  }
  #main-nav-items .nav-item.nav-active::before,
  #nav-right-menu .nav-item.nav-active::before {
    content: "";
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #2eafcf;
    height: 3px;
    width: 25px;
  }
  /* #main-nav-items .nav-item:before {
		content: "";
		position: absolute;
		z-index: -2;
		left: 0;
		right: 0;
		bottom: 0;
		background: #2eafcf;
		height: 3px;
		width: 25px;
		margin: auto;
		-webkit-transition-property: right;
		transition-property: right;
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
	} */
  /* #main-nav-items .nav-item:hover {
		opacity: 1 !important;
	}
	#main-nav-items .nav-item:hover:before {
		right: 0;
	} */
  /* #top-menu li li a:before {
		bottom: 10%;
		display: none;
	}
	#top-menu li li.current_page_item a {
		color: #ec2323;
	}
	#top-menu .menu-item .sub-menu li:hover a {
		color: #ec2323;
	}
	#top-menu .menu-item .sub-menu li:hover a:before {
		display: none;
	} */

  #main-nav-items .nav-item-parent {
    padding: 10px 30px 10px 15px;
  }
  #main-nav-items .sub-nav-item {
    padding: 15px 0;
    display: block;
  }

  /* @media(max-width: 1200px) {
		#main-nav-items .nav-item {
			padding: 10px 12px;
			line-height: 18px;
		}
		#main-nav-items .nav-item-parent {
			padding: 10px 20px 10px 12px;
		}
		#main-nav-items .nav-item-parent svg {
			right: 0;
		}
	} */
  /* @media(max-width: 1120px) {
		#main-nav-items {
			display: none;
		}
		#mobile-menu {
			display: block;
		}
	} */

  /* NAV TABS */
  .nav-tabs > li > a {
    @apply block
			font-bold
			leading-tight
			border-b-[3px]
			border-transparent
			sm:px-4 px-3
			text-blue
			hover:border-light-blue hover:border-b-[3px]
			pb-4
			text-sm;
  }
  .nav-tabs > li > a.active {
    @apply text-light-blue-500
			border-light-blue border-b-[3px];
  }

  /* TYPOGRAPHY */
  .header-1 {
    @apply text-3xl sm:text-4xl font-medium mb-5;
  }
  /* label {
		@apply
			font-Karla
	} */

  /* LINKS */

  a {
    @apply text-blue

			hover:text-light-blue

			transition-colors
			ease-in-out;
  }
  a:focus {
    /* @apply
			outline-dashed outline-2 outline-offset-4 rounded-sm */
  }
  a:hover {
    @apply text-light-blue-500;
  }

  /* DASHBOARD  NAV */
  #dashboard-main-nav {
    @apply overflow-auto
			h-3/4
			text-sm w-full pb-0 md:pb-14 lg:pb-14 xl:pb-14 2xl:pb-14;
  }
  .dashboard-nav-wrap {
    @apply flex items-center flex-row md:flex-col lg:flex-col xl:flex-col 2xl:flex-col;
  }
  .dash-main-nav-items {
    @apply flex flex-nowrap md:block lg:block xl:block 2xl:block;
  }
  .dash-main-nav-items > li {
  }
  .landing-logo {
    @apply px-10 py-5 md:p-10 lg:p-10 xl:p-10 2xl:p-10 max-w-xs relative z-30;
  }
  .dash-container {
    @apply relative;
  }
  .dash-sidebar-menu {
    @apply fixed flex flex-col top-0 left-0 w-14 hover:w-64 lg:w-64 bg-blue h-full text-white transition-all duration-300 border-none z-10;
  }
  /* .dash-right-container {
		@apply
		h-full lg:ml-64 ml-14
	} */
  .sidebar-nav {
    /* @apply */
    /* md:fixed lg:fixed xl:fixed 2xl:fixed 
		max-w-full 
		md:dash-sidebar
		pb-0 md:pb-20 lg:pb-20 xl:pb-20 2xl:pb-20 
		top-0 bottom-0 
		bg-blue dash-sidebar 
		shadow-2xl z-30 */
  }
  .dash-main-nav-items > li.active > a {
    @apply bg-blue-400 border-blue
			text-blue opacity-100;
  }
  .dash-main-nav-items > li > a {
    @apply flex px-8 py-3
			font-medium
			text-gray-500
			transition-opacity
			ease-in-out
			duration-300
			items-center
			opacity-60;
  }
  .dash-main-nav-items > li > a:hover {
    @apply text-blue opacity-100;
  }
  .dash-main-nav-items > li > a > .dash-nav-icon {
    @apply flex-none w-5 h-5 mt-0 mb-0;
  }
  .dash-main-nav-items > li > a > .dash-nav-label {
    @apply text-white;
  }
  .dash-main-nav-items > li > a:hover {
    @apply opacity-100;
  }

  .dash-right-content {
    @apply font-Karla font-normal
		h-screen bg-[#F5F6FA] lg:ml-64 ml-0 overflow-y-auto;
  }
  .dash-header {
    @apply bg-[#F5F6FA] lg:sticky relative top-0 z-20 lg:pt-10 pt-8 pb-5 md:px-8 px-6;
  }
  .dash-main-content {
    @apply font-Karla
		pb-10 md:px-8 px-6;
  }
  .dash-content {
    @apply bg-white md:p-10 sm:p-8 p-6 rounded-lg;
  }

  .dash-heading-1 {
    @apply text-gray-900 md:text-3xl text-2xl font-Karla font-bold leading-normal mb-0;
  }
  .dash-search-field {
    @apply relative max-w-sm ml-auto mr-0;
  }
  .search-field {
    @apply text-sm rounded-lg border-white h-auto py-[11px] pl-10;
  }
  .search-field {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  }

  /* DASHBOARD FILTERS */

  /* DASHBOARD CARDS */
  .dash-card {
    @apply bg-white rounded-lg shadow-sm mb-0;
  }
  .dash-card-body {
    @apply xl:py-8 py-6 xl:px-8 sm:px-6 px-5;
  }
  .dash-card-footer {
    @apply flex justify-end border-t border-t-gray-100 py-4 xl:px-8 sm:px-6 px-5;
  }

  .dash-card-wrap {
    @apply bg-white rounded-lg shadow-sm sm:py-10 py-8 sm:px-10 px-6;
  }

  .custom-label {
    @apply font-medium text-gray-500;
  }
  .custom-value {
    @apply text-black tracking-wide;
  }

  /* TABLE */

  .table {
    @apply w-full;
  }
  .table-border-gap {
    @apply border-separate;
  }
  .table.table-pad th {
    @apply font-medium text-xs text-gray-400 uppercase text-left
			px-2 py-2;
  }
  .table.table-pad td {
    @apply px-2 py-2;
  }
  .table.table-x-pad td,
  .table.table-x-pad th {
    @apply py-3 px-5;
  }

  .table.tableAppointmentPad th {
    @apply font-medium text-xs text-gray-400 uppercase text-left
			py-2;
  }
  .table.tableAppointmentPad td {
    @apply py-2;
  }
  .table.tableAppointment-x-Pad td,
  .table.tableAppointment-x-Pad th {
    @apply py-3;
  }

  /* DATEPICKER */

  /* .react-datepicker__navigation{
		@apply w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
	}
	.react-datepicker-popper .react-datepicker__navigation-icon{
		@apply !top-2
	}
	.react-datepicker__navigation */

  /* .react-datepicker__input-container input {
		@apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm form-input
	}
	
	.react-datepicker-popper {
		@apply z-40 w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded
	}
	
	.react-datepicker-left {
		@apply absolute left-0 right-auto top-11 transform-none !important
	}
	
	.react-datepicker-right {
		@apply absolute right-0 left-auto top-11 transform-none !important
	}
	
	.react-datepicker__portal {
		@apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded
	}
	
	.react-datepicker__month-container {
		@apply flex flex-col
	}
	
	.react-datepicker__month {
		@apply flex flex-col
	}
	
	.react-datepicker__current-month {
		@apply ml-2.5 text-lg font-semibold text-gray-800
	}
	
	.react-datepicker__week {
		@apply flex justify-around
	}
	
	.react-datepicker__day-names {
		@apply flex justify-around text-gray-400 font-medium text-center text-xs
	}
	
	.react-datepicker__day-name {
		@apply w-8 h-8 flex items-center justify-center py-1 rounded-full
	}
	
	.react-datepicker__navigation {
		@apply absolute top-2
	}
	
	.react-datepicker__navigation--previous {
		@apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
	}
	
	.react-datepicker__navigation--next {
		@apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
	}
	
	.react-datepicker__day {
		@apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded
	}
	
	.react-datepicker__day--disabled {
		@apply cursor-not-allowed opacity-40 hover:bg-transparent
	}
	
	.react-datepicker__day--outside-month {
		@apply text-gray-300
	}
	
	.react-datepicker__day--in-range {
		@apply bg-gray-200
	}
	
	.react-datepicker__day--in-selecting-range {
		@apply bg-blue-200
	}
	
	.react-datepicker__day--selecting-range-start {
		@apply bg-white border-2 border-blue-500
	}
	
	.react-datepicker__day--selecting-range-end {
		@apply bg-white border-2 border-blue-500
	}
	
	.react-datepicker__day--selected {
		@apply bg-blue-500 text-white
	}
	
	.react-datepicker__day--range-start {
		@apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
	}
	
	.react-datepicker__day--range-end {
		@apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
	} */

  .react-datepicker {
    @apply !text-sm font-Karla;
  }

  /* -------------------------------------------- */
  /* ------------ */
  /* COMPONENTS */
  /* ------------ */

  /* FORM BUILDER */
  /* #region */
  .q-btn-type {
    @apply flex w-full items-center bg-gray-100 transition-all duration-300 shadow-sm hover:shadow-lg border border-gray-300 hover:border-gray-400 p-3 rounded-md mb-5;
  }
  .q-type-icon {
    @apply justify-center rounded-full mr-3;
  }
  .q-type-label {
    @apply text-sm font-Karla text-gray-600;
  }
  /* #endregion */

  /* Label */

  .label-1 {
    @apply control-label mb-1 mt-0 block text-black;
  }
  .label-1-value {
    @apply text-gray-500;
  }

  /* Pagination */
  .pagination {
    @apply relative z-0 inline-flex rounded-md shadow-sm -space-x-px font-Karla font-normal flex-wrap;
  }
  .pagination > li:first-child > a {
    @apply relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-normal text-gray-500 hover:bg-gray-50;
  }
  .pagination > li:last-child > a {
    @apply relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-normal text-gray-500 hover:bg-gray-50;
  }
  .pagination > li > a {
    @apply bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-normal;
  }
  .pagination > li.active > a {
    @apply z-10 bg-indigo-50 border-blue-100 text-blue-600 relative inline-flex items-center px-4 py-2 border text-sm font-normal;
  }

  /* Dropdown */
  .menu-item {
    @apply text-sm font-Karla flex w-full items-center rounded-md px-2 py-2;
  }
  .menu-item-active {
    @apply bg-blue-500 text-white;
  }
  .menu-item-inactive {
    @apply text-gray-900;
  }

  /* Sidebar Menu */
  .sub-sidenav {
  }
  .sub-sidenav > li {
    @apply text-sm block;
  }
  .sidenav-link {
    @apply block pt-1 lg:pb-1 pb-3 px-5 mb-5 lg:border-l-2 border-l-0 lg:border-b-0 border-b-2 border-transparent text-gray-400 hover:text-light-blue-500;
  }
  .sidenav-link.sidenav-link-active {
    @apply border-light-blue-500 text-gray-800 hover:text-gray-800;
  }

  /* Alert */
  .basic-alert-1 {
    @apply p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800;
  }
  .basic-alert-2 {
    @apply p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800;
  }
  .basic-alert-3 {
    @apply p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800;
  }
  .basic-alert-4 {
    @apply p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800;
  }
  .basic-alert-5 {
    @apply p-4 mb-4 text-sm text-gray-700 bg-gray-100 rounded-lg dark:bg-gray-700 dark:text-gray-300;
  }

  .alert-link-1 {
    @apply bg-blue-100 rounded-lg py-5 px-6 text-base text-blue-700 mb-3;
  }
  .alert-link-2 {
    @apply bg-purple-100 rounded-lg py-5 px-6 text-base text-purple-700 mb-3;
  }
  .alert-link-3 {
    @apply bg-green-100 rounded-lg py-5 px-6 text-base text-green-700 mb-3;
  }
  .alert-link-4 {
    @apply bg-red-100 rounded-lg py-5 px-6 text-base text-red-700 mb-3;
  }
  .alert-link-5 {
    @apply bg-yellow-100 rounded-lg py-5 px-6 text-base text-yellow-700 mb-3;
  }
  .alert-link-6 {
    @apply bg-indigo-100 rounded-lg py-5 px-6 text-base text-indigo-700 mb-3;
  }
  .alert-link-7 {
    @apply bg-gray-50 rounded-lg py-5 px-6 text-base text-gray-500 mb-3;
  }
  .alert-link-8 {
    @apply bg-gray-300 rounded-lg py-5 px-6 text-base text-gray-800 mb-3;
  }

  .alert-icon-1 {
    @apply bg-blue-100 rounded-lg py-5 px-6 mb-3 text-base text-blue-700 inline-flex items-center w-full;
  }
  .alert-icon-2 {
    @apply bg-purple-100 rounded-lg py-5 px-6 mb-3 text-base text-purple-700 inline-flex items-center w-full;
  }
  .alert-icon-3 {
    @apply bg-green-100 rounded-lg py-5 px-6 mb-3 text-base text-green-700 inline-flex items-center w-full;
  }
  .alert-icon-4 {
    @apply bg-red-100 rounded-lg py-5 px-6 mb-3 text-base text-red-700 inline-flex items-center w-full;
  }
  .alert-icon-5 {
    @apply bg-yellow-100 rounded-lg py-5 px-6 mb-3 text-base text-yellow-700 inline-flex items-center w-full;
  }
  .alert-icon-6 {
    @apply bg-indigo-100 rounded-lg py-5 px-6 mb-3 text-base text-indigo-700 inline-flex items-center w-full;
  }
  .alert-icon-7 {
    @apply bg-gray-50 rounded-lg py-5 px-6 mb-3 text-base text-gray-500 inline-flex items-center w-full;
  }
  .alert-icon-8 {
    @apply bg-gray-300 rounded-lg py-5 px-6 mb-3 text-base text-gray-800 inline-flex items-center w-full;
  }

  /* Avatar */
  .basic-avatar-img {
    @apply rounded-full w-32;
  }

  .avatar-shadow-img {
    @apply rounded-full w-32 shadow-lg;
  }

  .avatar-square-img {
    @apply rounded-lg w-32;
  }

  /* Badges */
  .badge {
    @apply !font-Karla !font-semibold !text-xs text-center uppercase tracking-wider py-1 px-2 rounded;
  }
  .badge-wrap {
    @apply flex space-x-2 justify-center;
  }

  .basic-badge {
    @apply inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded;
  }
  .basic-badge-header {
    @apply text-4xl font-medium leading-tight text-gray-800;
  }

  .badge-size-1 {
    @apply inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded;
  }
  .badge-size-header-1 {
    @apply text-5xl font-medium leading-tight text-gray-800 mb-2.5 mt-0;
  }

  .badge-size-2 {
    @apply inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded;
  }
  .badge-size-header-2 {
    @apply text-4xl font-medium leading-tight text-gray-800 mb-2.5;
  }

  .badge-size-3 {
    @apply inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded;
  }
  .badge-size-header-3 {
    @apply text-3xl font-medium leading-tight text-gray-800 mb-2.5;
  }

  .badge-size-4 {
    @apply inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded;
  }
  .badge-size-header-4 {
    @apply text-2xl font-medium leading-tight text-gray-800 mb-2.5;
  }

  .badge-size-5 {
    @apply inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded;
  }
  .badge-size-header-5 {
    @apply text-xl font-medium leading-tight text-gray-800 mb-2.5;
  }

  .badge-size-6 {
    @apply inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded;
  }
  .badge-size-header-6 {
    @apply text-base font-medium leading-tight text-gray-800;
  }

  .badge-btn {
    @apply px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-600 hover:shadow-lg focus:bg-blue-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center;
  }
  .badge-btn-notif {
    @apply inline-block py-1 px-1.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded ml-2;
  }

  .badge-primary {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded;
  }
  .badge-secondary {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-purple-600 text-white rounded;
  }
  .badge-success {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-green-500 text-white rounded;
  }
  .badge-danger {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded;
  }
  .badge-warning {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-500 text-white rounded;
  }
  .badge-info {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-400 text-white rounded;
  }
  .badge-light {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-200 text-gray-700 rounded;
  }
  .badge-dark {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded;
  }

  .badge-pills-primary {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded-full;
  }
  .badge-pills-secondary {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-purple-600 text-white rounded-full;
  }
  .badge-pills-success {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-green-500 text-white rounded-full;
  }
  .badge-pills-danger {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-red-600 text-white rounded-full;
  }
  .badge-pills-warning {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-500 text-white rounded-full;
  }
  .badge-pills-info {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-400 text-white rounded-full;
  }
  .badge-pills-light {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-200 text-gray-700 rounded-full;
  }
  .badge-pills-dark {
    @apply text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-800 text-white rounded-full;
  }

  .badge-yellow {
    @apply bg-yellow-200 text-yellow-900 font-Karla text-sm text-center uppercase tracking-wider font-semibold p-1 rounded;
  }
  .badge-green {
    @apply bg-green-200 text-green-900 font-Karla text-sm text-center uppercase tracking-wider font-semibold p-1 rounded;
  }
  .badge-red {
    @apply bg-red-100 text-red-800 font-Karla text-sm text-center uppercase tracking-wider font-semibold p-1 rounded;
  }
  .badge-blue {
    @apply bg-blue-200 text-blue-800 font-Karla text-sm text-center uppercase tracking-wider font-semibold p-1 rounded;
  }
  .badge-skyblue {
    @apply bg-sky-200 text-blue-800 font-Karla text-sm text-center uppercase tracking-wider font-semibold p-1 rounded;
  }
  .badge-orange {
    @apply bg-orange-100 text-orange-900 font-Karla text-sm text-center uppercase tracking-wider font-semibold p-1 rounded;
  }
  .badge-lime {
    @apply bg-lime-100 text-lime-900 font-Karla text-sm text-center uppercase tracking-wider font-semibold p-1 rounded;
  }
  .badge-gray {
    @apply bg-gray-200 text-gray-800 font-Karla text-sm text-center uppercase tracking-wider font-semibold p-1 rounded;
  }

  /* Button Group */
  .btn-grp-wrap {
    @apply flex items-center justify-center mb-3;
  }

  .btn-grp-rounded-l {
    @apply rounded-l inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-0 active:bg-blue-800 transition duration-150 ease-in-out;
  }
  .btn-grp-inline-block {
    @apply inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-0 active:bg-blue-800 transition duration-150 ease-in-out;
  }
  .btn-grp-rounded-r {
    @apply rounded-r inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-0 active:bg-blue-800 transition duration-150 ease-in-out;
  }

  .btn-grp-active {
    @apply rounded-l
		px-6
		py-2.5
		bg-blue-800
		text-white
		font-medium
		text-xs
		leading-tight
		uppercase
		hover:bg-blue-700
		focus:bg-blue-700 focus:outline-none focus:ring-0
		active:bg-blue-800
		transition
		duration-150
		ease-in-out;
  }

  .btn-grp-outline-rounded-l {
    @apply rounded-l
		px-6
		py-2
		border-2 border-blue-600
		text-blue-600
		font-medium
		text-xs
		leading-tight
		uppercase
		hover:bg-black hover:bg-opacity-5
		focus:outline-none focus:ring-0
		transition
		duration-150
		ease-in-out;
  }
  .btn-grp-outline {
    @apply px-6
		py-2
		border-t-2 border-b-2 border-blue-600
		text-blue-600
		font-medium
		text-xs
		leading-tight
		uppercase
		hover:bg-black hover:bg-opacity-5
		focus:outline-none focus:ring-0
		transition
		duration-150
		ease-in-out;
  }
  .btn-grp-outline-rounded-r {
    @apply rounded-r
		px-6
		py-2
		border-2 border-blue-600
		text-blue-600
		font-medium
		text-xs
		leading-tight
		uppercase
		hover:bg-black hover:bg-opacity-5
		focus:outline-none focus:ring-0
		transition
		duration-150
		ease-in-out;
  }

  /* Buttons */
  .btn {
    @apply rounded-md
		leading-4
		font-semibold
		font-Karla
		py-3 px-5;
  }
  .btn-wrap {
    @apply flex space-x-2 justify-center;
  }

  .basic-btn {
    @apply px-6 py-2.5 bg-blue-600 text-white font-normal text-xs leading-tight uppercase rounded hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-primary {
    @apply px-6 py-3 bg-blue text-white font-normal leading-tight rounded hover:bg-blue-300 focus:bg-blue-300 focus:outline-none focus:ring-0 active:bg-blue-300 transition duration-150 ease-in-out;
  }
  .btn-secondary {
    @apply px-6 py-3 bg-light-blue text-white font-normal leading-tight transition duration-300 ease-in-out;
  }
  .btn-primary:hover,
  .btn-secondary:hover {
    background: #95d50b;
  }
  .btn-secondary:focus {
    @apply bg-light-blue-400 shadow-lg outline-none ring-0;
  }
  .btn-secondary:active {
    @apply active:bg-light-blue-400 active:shadow-lg;
  }
  .btn-cancel,
  .btn-close {
    @apply bg-gray-200 text-gray-800 font-normal border-gray-200 border-2 hover:bg-gray-600 focus:bg-gray-600 hover:border-gray-600 focus:border-gray-600 focus:text-white;
  }

  .btn-success {
    @apply px-6 py-3 bg-green-500 text-white font-normal leading-tight rounded hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-700 ease-in-out;
  }
  .btn-danger {
    @apply px-6 py-3 bg-red-600 text-white font-normal leading-tight hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-700 active:shadow-lg transition duration-700 ease-in-out;
  }
  .btn-warning {
    @apply px-6 py-3 bg-yellow-500 text-white font-normal leading-tight rounded hover:bg-yellow-600 hover:shadow-lg focus:bg-yellow-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-700 active:shadow-lg transition duration-150 ease-in-out disabled:bg-yellow-700;
  }
  .btn-info {
    @apply px-6 py-3 bg-blue-400 text-white font-normal leading-tight rounded hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-light {
    @apply px-6 py-3 bg-gray-200 text-gray-700 font-normal leading-tight rounded hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-light:hover {
    @apply text-black;
  }
  .btn-light-gray {
    @apply px-6 py-3 bg-gray-200 text-gray-700 font-normal leading-tight rounded hover:bg-blue-300 focus:bg-blue-300 focus:outline-none focus:ring-0 active:bg-blue-300 transition duration-150 ease-in-out;
  }
  .btn-dark {
    @apply px-6 py-3 bg-gray-800 text-white font-normal leading-tight rounded hover:bg-gray-900 hover:shadow-lg focus:bg-gray-900 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-900 active:shadow-lg transition duration-150 ease-in-out;
  }
  .btn-link {
    @apply px-6 py-3 bg-transparent text-blue-600 font-normal leading-tight rounded hover:text-blue-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 transition duration-150 ease-in-out;
  }

  .btn-outline-primary {
    @apply px-6 py-2 border-2 border-blue-600 text-blue-600 font-normal leading-tight rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out;
  }
  .btn-outline-secondary {
    @apply px-6 py-2 border-2 border-purple-600 text-purple-600 font-normal leading-tight rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out;
  }
  .btn-outline-success {
    @apply px-6 py-2 border-2 border-green-500 text-green-500 font-normal leading-tight rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out;
  }
  .btn-outline-danger {
    @apply px-6 py-2 border-2 border-red-600 text-red-600 font-normal leading-tight rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out;
  }
  .btn-outline-warning {
    @apply px-6 py-2 border-2 border-yellow-500 text-yellow-500 font-normal leading-tight rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out;
  }
  .btn-outline-info {
    @apply px-6 py-2 border-2 border-blue-400 text-blue-400 font-normal leading-tight rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out;
  }
  .btn-outline-light {
    @apply px-6 py-2 border-2 border-gray-200 text-gray-200 font-normal leading-tight rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out;
  }
  .btn-outline-dark {
    @apply px-6 py-2 border-2 border-gray-800 text-gray-800 font-normal leading-tight rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out;
  }

  .btn-icon {
    @apply px-6 pt-2.5 pb-2 bg-blue-600 text-white font-normal text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center;
  }

  .btn-green {
    background-color: #95d50b;
  }

  /* BUTTON SIZES */
  .btn-sm {
    @apply sm:py-[10px] py-2 sm:px-4 px-3 text-sm;
  }
  .btn-xs {
    @apply px-3
			text-xs;
  }
  .btn-lg {
    @apply py-2
			px-5
			text-base;
  }
  .btn-table {
    @apply font-Karla font-semibold text-xs py-2 px-3 max-w-[85px] w-full;
  }
  .btn-table-2 {
    @apply text-sm py-2 px-4;
  }

  /* BUTTON - DASHBOARD */
  .btn-dash {
    @apply font-Karla font-medium;
  }
  .btn-dash-default {
    @apply bg-white text-blue font-medium border-blue border hover:bg-blue-300 focus:bg-blue-300 hover:border-blue-300 hover:text-white;
  }
  .btn-dash-primary {
    @apply bg-blue text-white font-Karla font-medium border-blue border-2 hover:bg-blue-300 focus:bg-blue-300 hover:border-blue-300;
  }
  .btn-dash-secondary {
    @apply bg-light-blue text-white font-Karla font-medium border-light-blue border-2 hover:bg-[#95d50b] focus:bg-[#95d50b] hover:border-[#95d50b] focus:border-[#95d50b];
  }
  .btn-dash-tertiary {
    @apply bg-light-blue text-white font-Karla font-medium border-light-blue border-2 hover:bg-light-blue-400 focus:bg-light-blue-400 hover:border-light-blue-400;
  }
  .btn-dash-gray,
  .btn-dash-cancel {
    @apply bg-gray-200 text-gray-800 font-Karla font-medium border-gray-200 border-2 hover:bg-gray-600 focus:bg-gray-600 hover:border-gray-600 focus:border-gray-600 focus:text-white;
  }
  .btn-header {
    @apply text-sm sm:py-2 py-[8px] px-4;
  }
  .btn-dash-sm {
    @apply py-[6px] px-[15px];
  }

  /* Cards */
  .card-wrap {
    @apply flex justify-center;
  }
  .card-title {
    @apply text-gray-900 text-xl font-medium mb-2;
  }
  .card-content {
    @apply text-gray-700 text-base mb-4;
  }
  .card-btn {
    @apply inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out;
  }
  .card-horizontal-img {
    @apply w-full h-96 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg;
  }
  .card-rounded-lg {
    @apply rounded-lg bg-white shadow-lg;
  }

  /* Chips */
  .chips-wrap {
    @apply flex flex-wrap justify-center space-x-2;
  }
  .chips-text {
    @apply px-4 py-2 rounded-full text-gray-500 bg-gray-200 font-semibold text-sm flex items-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease-in-out;
  }
  .chips-rounded {
    @apply rounded-full text-gray-500 bg-gray-200 font-semibold text-sm flex items-center cursor-pointer active:bg-gray-300 transition duration-300 ease-in-out w-max;
  }
  .chips-outline {
    @apply px-4 py-2 rounded-full border border-gray-300 text-gray-500 font-semibold text-sm flex items-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease-in-out;
  }

  /* Gallery */
  .gallery-wrap {
    @apply flex flex-wrap -m-1 md:-m-2;
  }
  .gallery-img {
    @apply block object-cover object-center w-full h-full rounded-lg;
  }

  /* Headings */
  .heading {
    @apply font-medium leading-tight mt-0 mb-2 text-blue-600;
  }

  /* Hover */
  .hover-wrap {
    @apply relative overflow-hidden bg-no-repeat bg-cover max-w-xs;
  }
  .hover-img {
    @apply absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-30 transition duration-300 ease-in-out;
  }
  .hover-gradient {
    @apply absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden opacity-0 transition duration-300 ease-in-out bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 hover:opacity-70;
  }
  .hover-zoom {
    @apply max-w-xs hover:scale-110 transition duration-300 ease-in-out;
  }

  /* Image */
  .fullwidth-img {
    @apply max-w-full h-auto;
  }
  .thumbnail-img {
    @apply p-1 bg-white border rounded max-w-sm;
  }
  .img-shadow {
    @apply max-w-sm h-auto shadow-lg;
  }
  .img-hover-shadow {
    @apply max-w-sm h-auto transition-shadow ease-in-out duration-300 shadow-none hover:shadow-xl;
  }

  /* Links */
  .primary-link {
    @apply text-blue-600 hover:text-blue-700 transition duration-300 ease-in-out mb-4;
  }
  .secondary-link {
    @apply text-purple-600 hover:text-purple-700 transition duration-300 ease-in-out mb-4;
  }
  .success-link {
    @apply text-green-500 hover:text-green-600 transition duration-300 ease-in-out mb-4;
  }
  .danger-link {
    @apply text-red-600 hover:text-red-700 transition duration-300 ease-in-out mb-4;
  }
  .warning-link {
    @apply text-yellow-500 hover:text-yellow-600 transition duration-300 ease-in-out mb-4;
  }
  .info-link {
    @apply text-blue-400 hover:text-blue-500 transition duration-300 ease-in-out mb-4;
  }
  .light-link {
    @apply text-gray-200 hover:text-gray-300 transition duration-300 ease-in-out mb-4;
  }
  .dark-link {
    @apply text-gray-800 hover:text-gray-900 transition duration-300 ease-in-out mb-4;
  }
  .white-link {
    @apply text-white hover:text-gray-100 transition duration-300 ease-in-out;
  }
  .black-link {
    @apply text-black transition duration-300 ease-in-out;
  }

  .hover-link {
    @apply underline decoration-transparent hover:decoration-inherit transition duration-300 ease-in-out;
  }

  /* List Group */
  .list-grp-wrap {
    @apply bg-white rounded-lg border border-gray-200 w-96 text-gray-900;
  }
  .list-grp-flush {
    @apply bg-white rounded-lg w-96 text-gray-900;
  }
  .list-grp-border {
    @apply px-6 py-2 border-b border-gray-200 w-full;
  }

  /* Mask */
  .mask-wrap {
    @apply relative overflow-hidden bg-no-repeat bg-cover;
  }
  .mask-wrapper {
    @apply absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden;
  }

  /* Notification */

  /* -------------------------------------------- */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Palanquin Bold", Helvetica, sans-serif;
}
body {
  font-family: "Palanquin Regular", Helvetica, sans-serif;
  /* font-size: 18px;
	line-height: 32px; */
}

/* HOME */
.landing-header {
  width: 514px;
}
.landing-right-container {
  padding-left: 514px;
}
.search-btn svg {
  width: 100%;
}
.landing-form .form-control {
  border: 0;
  box-shadow: none;
  background: transparent;
  padding: 0;
}
.landing-form .form-control:hover,
.landing-form .form-control:focus,
.landing-form .form-control:active {
  border: 0;
  outline: 0;
}

.line-div {
  min-height: 1px;
  width: 100%;
  background-color: #e8e8e8;
  position: absolute;
  z-index: 1;
  text-align: center;
  left: 0;
  right: 0;
  top: 10px;
}
.or-text {
  position: relative;
  z-index: 2;
  padding: 0 10px;
  background-color: #fff;
  color: #939393;
}

/* SERVICES */
/* .services-bg {
	border-bottom-left-radius: 50% 50px;
    border-bottom-right-radius: 50% 50px;
} */

/* PATIENT PROFILE */
.patient-no {
  letter-spacing: 4px;
}

/* FORMS */
.form-group {
  margin-bottom: 15px;
}
.field-label {
  @apply text-gray-500 text-sm font-Karla font-medium;
}
.field-value {
  @apply text-gray-800 text-base font-Karla;
}
.answer-field {
  @apply text-gray-800 text-base font-Karla bg-gray-100 border border-gray-300 rounded-[4px] py-2 px-3 min-h-[45px];
}
.custom-fw-field .react-datepicker-wrapper {
  @apply w-full;
}
.custom-fw-field .react-datepicker-wrapper button {
  @apply w-full;
}
.custom-number-field::-webkit-outer-spin-button,
.custom-number-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.custom-number-field[type="number"] {
  -moz-appearance: textfield;
}
/* BACKGROUND */
.bg-1 {
  background: url("../public/assets/img/Landing.png") center center no-repeat;
  background-size: cover;
}

/* FOOTER */
.footer-social-icon li {
  max-width: 16px;
  width: 100%;
  vertical-align: middle;
}

/* DASHBOARD */
.dash-sidebar .side-header {
  letter-spacing: 3px;
}

.dash-data-header {
  @apply py-4 px-4;
}
.dash-data-header .dash-data-title {
  @apply text-xs text-gray-400 uppercase tracking-wider;
}
.dash-data-body {
  @apply text-sm;
}
.dash-data-body .grid {
  @apply py-4 px-4;
}

/* KYNATECH COMPONENTS */

/* TABLES */
.table-container {
  min-width: 1000px;
  overflow: auto;
  position: relative;
}

.tbl-custom {
  @apply font-Karla;
}
.tbl-custom thead tr th,
.tbl-custom thead tr td {
  @apply font-medium text-gray-500 text-center text-xs uppercase tracking-wider;
}
.tbl-custom tbody tr td {
  @apply text-sm;
}
.dash-custom-table thead {
  @apply border-gray-200 border-b;
}
.dash-custom-table thead tr th {
  @apply text-gray-400 text-center text-xs font-normal uppercase tracking-wider
	py-3 px-2;
}
.dash-custom-table tbody tr td {
  @apply text-xs 
	py-4 px-4;
}

/* MEDIA BREAKPOINTS */

/* 2xl */
@media screen and (max-width: 1536px) {
}

/* xl */
@media screen and (max-width: 1280px) {
  /* .landing-header{
		width: 350px;
	}
	.landing-right-container{
		padding-left: 350px;
	} */
}

/* lg */
@media screen and (max-width: 1024px) {
  /* .landing-header{
		width: 100%;
	}
	.landing-right-container{
		padding-left: 0;
	} */
}
@media screen and (max-width: 1023px) {
  /* DASHBOARD */
  /* .dash-right-container {
		padding-left: 112px;
	}
	.dash-header{
		left: 112px;
	} */
}

/* md */
@media screen and (max-width: 768px) {
}

/* sm */
@media (max-width: 767px) {
  /* DASHBOARD */
  /* .dash-sidebar {
		max-width: 100%;
	} */
  /* .dash-main-nav-items > li {
		display: inline-block;
	}
	.dash-main-nav-items {
		text-align: center;
	}
	.dash-right-container {
		padding-left: 0;
	}
	.dash-header{
		left: 0;
	} */
}
@media screen and (max-width: 640px) {
  /* .services-bg {
		border-bottom-left-radius: 50% 30px;
		border-bottom-right-radius: 50% 30px;
	} */
}

/* PATIENT PROFILE */
.schedule-blue {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 35%);
  -webkit-box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
}

/* PIE CHART */
.recharts-wrapper {
  margin: auto;
}

.react-datepicker {
  border: 0 !important;
  padding: 14px !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  font-family: inherit !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  display: none !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #fff !important;
}
.react-datepicker__navigation-icon {
  top: 12px !important;
}
.react-datepicker__header {
  background-color: #fff !important;
  border-bottom-color: #f1f1f1 !important;
  padding-top: 0 !important;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem !important;
  height: 2rem !important;
  line-height: 2rem !important;
}

/* MAIN NAVIGATION */
#main-navigation {
  box-shadow: 0px 4px 14px -8px rgb(0 0 0 / 10%);
}

/* UPLOADCARE BTN */
.uploadcare--widget__button {
  background: #2cb1ce;
  color: #fff;
  padding: 8px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
}
.uploadcare--widget__button:hover,
.uploadcare--widget__button:focus,
.uploadcare--widget__button:active {
  background: #1479c9;
  font-size: 14px;
}
.uploadcare--button_primary {
  @apply bg-blue
	border-blue
	text-base
	cursor-pointer;
}
.uploadcare--dialog__close {
  @apply cursor-pointer;
}

/* COMING SOON */
.social-icon.facebook-icon span img {
  width: 12px !important;
}
.social-icon span img {
  width: 25px !important;
}

.contact-social-icon.facebook-icon span img {
  width: 8px !important;
}
.contact-social-icon span img {
  width: 18px !important;
}

.pmha-photo-wrap img {
  border-radius: 8px;
}
.proof-image img {
  object-fit: contain;
}

.app-settings-nav-icon {
  /* @apply  */
  /* relative w-full */
  /* -right-1 -top-1 w-5 h-5 rounded-full bg-white flex items-center justify-center cursor-pointer z-50
	hover:bg-blue hover:text-white */
}
.nav-app-icon {
  @apply bg-white absolute -top-1 left-6 border border-gray-500 rounded-full cursor-pointer;
}
.app-settings-nav-content {
  @apply bg-white absolute left-2 top-[30px] w-full shadow-md z-10 rounded-md p-2;
}

/* SLIDER */
.swiper-slider-1 .swiper-pagination {
  bottom: 50px !important;
}
.swiper-slider-1 .swiper-button-next:after,
.swiper-slider-1 .swiper-button-prev:after {
  font-size: 22px;
  /* color: rgba(153, 24, 32, 1); */
  color: #fff;
}
.swiper-slider-1 .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: rgba(153, 24, 32, 0.9);
  background: #fff;
  opacity: 0.8;
}
.swiper-slider-1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 30px;
  height: 10px;
  border-radius: 20px;
  background: #fff;
  opacity: 1;
}

/* SIDEBAR CUSTOM SCROLL */
/* width */
#sidebar-menu::-webkit-scrollbar {
  width: 1px;
}

/* Track */
#sidebar-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
#sidebar-menu::-webkit-scrollbar-thumb {
  @apply bg-gray-100;
}

/* Handle on hover */
#sidebar-menu::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-100;
}

.tag-delete-icon svg {
  @apply text-red-600;
}

.post-thumbnail-image > a > span {
  @apply !relative !mt-5;
}

/* MODALS */
.modal-close {
  @apply absolute top-4 right-4;
}

/* POWER OF ZERO */
.poz-header-divider {
  clip-path: polygon(0 70%, 100% 0, 100% 100%, 0% 100%);

  @apply bg-[#5256a0] min-h-[200px] w-full absolute -bottom-1 left-0 right-0 z-30;
}

.divider-purple {
  --bg-overlay-color: rgb(255, 255, 255);
  --bg-gradient: none;
  min-width: 980px;
  --divider-top-size: 1203px 100%;
  --divider-top-height: 50px;
  --divider-top-color: #5256a0;
  --divider-top-opacity: 1;
  --divider-top-flip: scale(1, -1);
  --divider-top-layers-pin-factor: 0;
  --divider-top-filter: none;
}
@media (max-width: 639px) {
  .poz-header-divider {
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0% 100%);
  }
}

.booking-date .react-datepicker-wrapper,
.custom-date-field .react-datepicker-wrapper {
  width: 100%;
}

.blog-body-content .blog-body > div {
  @apply p-0;
}
.cert-image-wrap {
  @apply border-[1px] border-gray-300 py-2 px-2 max-w-xs rounded-sm;
}
.cert-image-wrap img {
  @apply object-contain;
}
.custom-date-field .react-datepicker-popper {
  @apply z-20;
}

/* BLOGS */
.blog-body p {
  @apply mb-5;
}

.custom-job-modal .modal-header {
  @apply hidden;
}
