@font-face {
    font-family: 'Karla';
    src: url('Karla-Regular.woff2') format('woff2'),
        url('Karla-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url('Karla-Bold.woff2') format('woff2'),
        url('Karla-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CantoraOne';
    src: url('CantoraOne-Regular.woff2') format('woff2'),
        url('CantoraOne-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Palanquin Bold';
    src: url('Palanquin-Bold.woff2') format('woff2'),
        url('Palanquin-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Palanquin SemiBold';
    src: url('Palanquin-SemiBold.woff2') format('woff2'),
        url('Palanquin-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Palanquin Regular';
    src: url('Palanquin-Regular.woff2') format('woff2'),
        url('Palanquin-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

